'use strict';

angular.module('kljDigitalLibraryApp')
    .controller('SaveSearchModelCtrl', ["$scope", "$uibModalInstance", "confirmationMessage",
        function($scope, $uibModalInstance, confirmationMessage) {
            $scope.confirmationMessage = confirmationMessage;

            $scope.save = function(name) {

                console.log("save-->");


                $uibModalInstance.close(name);
            }

            $scope.cancel = function() {

                console.log("cancel!");
                $uibModalInstance.close();
            }

        }
    ]);